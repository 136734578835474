import React, { useState, useEffect } from 'react';
import Pagination from './Pagination';
import jsonData from '../data.json';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Events from './UpcomingEvents';

const TechNews = () => {
    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentStickyPage, setCurrentStickyPage] = useState(1);
    const [techInfo, setTechInfo] = useState([]);
    const [filteredTechInfo, setFilteredTechInfo] = useState([]);
    const [stickyInfo, setStickyInfo] = useState([]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isEventAccordionOpen, setIsEventAccordionOpen] = useState(false);
    const [expandedItems, setExpandedItems] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    const totalPages = Math.ceil(filteredTechInfo.length / itemsPerPage);
    const totalStickyPages = Math.ceil(stickyInfo.length / itemsPerPage);

    const currentNews = filteredTechInfo.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const stickyNews = stickyInfo.slice(
        (currentStickyPage - 1) * itemsPerPage,
        currentStickyPage * itemsPerPage
    );

    useEffect(() => {
        setTechInfo(jsonData);
        setFilteredTechInfo(jsonData);
        setStickyInfo(jsonData.map((item) => item.title));
    }, []);

    const truncateContent = (content) => {
        const words = content.split(' ');
        return words.length > 150 ? words.slice(0, 150).join(' ') + '...' : content;
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const toggleExpand = (index) => {
        setExpandedItems((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        const filteredData = techInfo.filter((news) =>
            news.title.toLowerCase().includes(query)
        );

        setFilteredTechInfo(filteredData);
        setCurrentPage(1);
    };

    return (
        <>
            <title>Upaman - Latest Tech News, AI Trends, Gadgets Reviews, General Knowledge, Fascinating Animal Fun Factsand Fun Facts</title>
            <meta name="description" content="Stay informed with Upaman - Your go-to platform for the latest technology updates, intriguing general knowledge, and amusing animal fun facts. Explore the wonders of the world with us!. Discover the latest in technology news, AI trends, gadget reviews, and fascinating facts at Upaman. Stay updated on cutting-edge developments and explore engaging content about technology, science, and more." />
            <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* First Column */}
                    <div className="md:col-span-2 order-2 md:order-none space-y-6">
                        {/* Search Bar */}
                        <div className="mb-6">
                            <input
                                type="text"
                                placeholder="Search news..."
                                value={searchQuery}
                                onChange={handleSearch}
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        {currentNews.map((news, index) => {
                            const isExpanded = expandedItems[index];
                            return (
                                <div key={index}>
                                    <div className="flex flex-col md:flex-row mb-4">
                                        <div className="md:w-1/4 mb-4 md:mb-0 md:mr-4 relative" style={{ height: '200px' }}>
                                            <img 
                                                src={news.img_link} 
                                                alt={news.title} 
                                                className="absolute inset-0 w-full h-full object-cover rounded-md"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                        <div className="w-full md:w-3/4">
                                            <h2 className="text-xl md:text-2xl font-bold mb-2">{news.title}</h2>
                                            <p className="text-gray-700 text-sm mb-2">
                                                {isExpanded ? news.content : truncateContent(news.content)}
                                                {news.content.length > 150 && !isExpanded && (
                                                    <span 
                                                        onClick={(e) => { e.stopPropagation(); toggleExpand(index); }} 
                                                        className="text-blue-500 cursor-pointer ml-1"
                                                    >
                                                        Read more
                                                    </span>
                                                )}
                                            </p>
                                            <p className="text-gray-500 text-xs mb-1">
                                                Published on: {formatDate(news.published)} | 
                                                <a 
                                                    href={news.source_link} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className="text-blue-500 ml-1 underline"
                                                >
                                                    Read Full Article from Source
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    {index < currentNews.length - 1 && (
                                        <hr className="border-t-2 border-gray-300 my-4"/>
                                    )}
                                </div>
                            );
                        })}

                        {/* Pagination for news items */}
                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={setCurrentPage}
                        />
                    </div>
                    
                    {/* Second Column - Appears first in mobile view */}
                    <div className="md:col-span-1 order-1 md:order-none sticky top-0 bg-gray-100 p-4 md:border-l-2 md:border-gray-300">
                        {/* Accordion for mobile view */}
                        <div className="block md:hidden w-full">
                            <button 
                                className="flex justify-between items-center w-full bg-gray-200 p-2 rounded-md mb-4"
                                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                            >
                                <h3 className="text-xl font-semibold">Trending News</h3>
                                {isAccordionOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </button>
                            {isAccordionOpen && (
                                <div>
                                    <ul className="space-y-2">
                                        {stickyNews.map((news, index) => (
                                            <li key={index} className="text-gray-700 text-sm">{news}</li>
                                        ))}
                                    </ul>
                                    
                                    {/* Pagination for sticky news */}
                                    <Pagination 
                                        currentPage={currentStickyPage}
                                        totalPages={totalStickyPages}
                                        onPageChange={setCurrentStickyPage}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Normal view for desktop */}
                        <div className="hidden md:block">
                            <h3 className="text-xl font-semibold mb-4">Trending News</h3>
                            <ul className="space-y-2">
                                {stickyNews.map((news, index) => (
                                    <li key={index} className="text-gray-700 text-sm">{news}</li>
                                ))}
                            </ul>
                            
                            {/* Pagination for sticky news */}
                            <Pagination 
                                currentPage={currentStickyPage}
                                totalPages={totalStickyPages}
                                onPageChange={setCurrentStickyPage}
                            />
                        </div>
                        {/* Upcoming Events Component */}
                        <div className="mt-6">
                            <div className="block md:hidden w-full">
                                <button 
                                    className="flex justify-between items-center w-full bg-gray-200 p-2 rounded-md mb-4"
                                    onClick={() => setIsEventAccordionOpen(!isEventAccordionOpen)}
                                >
                                    <h3 className="text-xl font-semibold">Events</h3>
                                    {isEventAccordionOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                                {isEventAccordionOpen && (
                                <Events />
                                )}
                            </div>
                            {/* Normal view for desktop */}
                            <div className="hidden md:block">
                            <h3 className="text-xl font-semibold mb-4">Events</h3>
                                {/* <h3 className="text-xl font-semibold mb-4">Trending News</h3> */}
                                <Events/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TechNews;
